export function showAsCurrency(value: number, currencyIso: string): string {
  if (currencyIso === "PLN") {
    const valueFormated = Intl.NumberFormat("no-ND", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
    return valueFormated + " zł";
  }
  return "";
}
