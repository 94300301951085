export function showAsPercent(value: number, asNumber = false) {
  if (asNumber === false) {
    return value.toFixed() + " %";
  } else {
    return Number(value.toFixed()) + " %";
  }
}

export function showAsPercent2(value: number, asNumber = false) {
  if (asNumber === false) {
    return value.toFixed(2) + " %";
  } else {
    return Number(value.toFixed(2)) + " %";
  }
}
