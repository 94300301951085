
import {
  Ref,
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";

import type { CustomWindow } from "@/types/window";
import type { sfConfType } from "@/types/sfConfType";

import { showAsCurrency } from "@/services/showAsCurrency";
import { showAsPercent2 } from "@/services/showAsPercent";

import InputNumberButtons from "./ui/InputNumberButtons.vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

declare let window: CustomWindow;

export default defineComponent({
  name: "WebWidget",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    contactTitle: {
      type: String,
      required: true,
      default: "",
    },
    filterButton: {
      type: String,
      required: true,
      default: "",
    },
    resultButton: {
      type: String,
      required: true,
      default: "",
    },
    contactFormButton: {
      type: String,
      required: true,
      default: "",
    },
    campaignId: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    InputNumberButtons,
    VueDatePicker,
  },
  setup: function (props) {
    const sfConfig: Ref<sfConfType | null> = ref(null);

    const onProgress = (event: any) => {
      console.log(`Processed: ${event} / 100`);
    };
    const now = new Date();
    const nextDate = new Date();

    nextDate.setMonth(nextDate.getMonth() + 1);

    const buyDate = ref(now);
    const sellDate = ref(nextDate);

    const flatArea = ref(37);
    const changedFlatArea = (value: number) => {
      flatArea.value = value;
    };

    const roomsCount = ref(2);
    const changedRoomsCount = (value: number) => {
      roomsCount.value = value;
    };

    const purchasePrice = ref(560000);
    const changedPurchasePrice = (value: number) => {
      purchasePrice.value = value;
    };

    const purchasePriceGarage = ref(8000);
    const changedPurchasePriceGarage = (value: number) => {
      purchasePriceGarage.value = value;
    };

    const purchasePriceBasement = ref(24000);
    const changedPurchasePriceBasement = (value: number) => {
      purchasePriceBasement.value = value;
    };

    const renovationPrice = ref(12000);
    const changedRenovationPrice = (value: number) => {
      renovationPrice.value = value;
    };

    const interiorFinishingPrice = ref(6000);
    const changedInteriorFinishingPrice = (value: number) => {
      interiorFinishingPrice.value = value;
    };
    //--------------------------------------------------------------------------------
    const sellPrice = ref(623000);
    const changedSellPrice = (value: number) => {
      sellPrice.value = value;
    };

    const sellPriceGarage = ref(9500);
    const changedSellPriceGarage = (value: number) => {
      sellPriceGarage.value = value;
    };

    const sellPriceBasement = ref(26000);
    const changedSellPriceBasement = (value: number) => {
      sellPriceBasement.value = value;
    };
    //------------------------------- obliczenia --------------------------------------
    //laczna cena zakupu nieruchomosci
    const totalPurchasePrice = computed(() => {
      const tmpVal =
        purchasePrice.value +
        purchasePriceGarage.value +
        purchasePriceBasement.value;
      return tmpVal;
    });

    //laczny koszt inwestycji
    const totalCosts = computed(() => {
      const tmpVal = totalPurchasePrice.value + renovationPrice.value;
      return tmpVal;
    });

    //cena zakupu za metr
    const areaPurchasePrice = computed(() => {
      const tmpVal = totalPurchasePrice.value / flatArea.value;
      return tmpVal;
    });

    //cena wykanczania za metr
    const areaRenovationPrice = computed(() => {
      const tmpVal = renovationPrice.value / flatArea.value;
      return tmpVal;
    });

    //cena urzadzenia za metr
    const areaInteriorFinishingPrice = computed(() => {
      const tmpVal = interiorFinishingPrice.value / flatArea.value;
      return tmpVal;
    });

    //Laczna cena sprzedazy
    const totalSellPrice = computed(() => {
      const tmpVal =
        sellPrice.value + sellPriceGarage.value + sellPriceBasement.value;
      return tmpVal;
    });

    //cena sprzedazy za metr
    const areaSellPrice = computed(() => {
      const tmpVal = totalSellPrice.value / flatArea.value;
      return tmpVal;
    });

    // zysk z inwestycji
    const investProfit = computed(() => {
      const tmpVal = totalSellPrice.value - totalCosts.value;
      return tmpVal;
    });

    // z m2
    const areaProfit = computed(() => {
      const tmpVal = investProfit.value / flatArea.value;
      return tmpVal;
    });

    //za mieszkanie po remontach
    const flatProfitAfterRenovation = computed(() => {
      const tmpVal =
        sellPrice.value - purchasePrice.value - renovationPrice.value;
      return tmpVal;
    });

    //za miejsca postojowe/garaz
    const garageProfit = computed(() => {
      const tmpVal = sellPriceGarage.value - purchasePriceGarage.value;
      return tmpVal;
    });

    // za piwnice
    const basementProfit = computed(() => {
      const tmpVal = sellPriceBasement.value - purchasePriceBasement.value;
      return tmpVal;
    });

    //wartosc podatku dochodowego
    const incomeTaxValue = computed(() => {
      const tmpVal = (investProfit.value / 100) * incomeTax.value;
      return tmpVal;
    });

    //wartosc podatku zdrowotnego
    const healthTaxValue = computed(() => {
      const tmpVal = (investProfit.value / 100) * healthTax.value;
      return tmpVal;
    });

    //zysk po podatkach
    const afterTaxProfit = computed(() => {
      const tmpVal =
        investProfit.value - incomeTaxValue.value - healthTaxValue.value;
      return tmpVal;
    });

    //stopa zwrotu
    const profitRate = computed(() => {
      const tmpVal =
        (totalSellPrice.value /
          (totalCosts.value + incomeTaxValue.value + healthTaxValue.value) -
          1) *
        100;
      return tmpVal;
    });

    // czas trwania
    const profitPeriod = computed(() => {
      const diffInMilliseconds =
        sellDate.value.getTime() - buyDate.value.getTime();

      const diffInMonths = diffInMilliseconds / (1000 * 60 * 60 * 24) / 30;
      const tmpVal = Number(diffInMonths.toFixed());
      return tmpVal;
    });

    // --------------------------------------------------
    // ---------- selects ------------------
    const incomeTax = ref(19);
    const incomeTaxItems = [
      { id: "19", title: "19 %" },
      { id: "17", title: "17 %" },
      { id: "12", title: "12 %" },
      { id: "8", title: "8 %" },
    ];

    const healthTax = ref(4.9);
    const healthTaxItems = [
      { id: "4.9", title: "4,9 %" },
      { id: "0", title: " " },
    ];

    const printPdf = () => {
      console.log("fff");
    };
    //----------------------
    const modal = ref(false);
    const inputForceUpdate = ref(1);

    const isLoading = ref(false);
    const showResults = ref(false);

    const closeContact = () => {
      modal.value = !modal.value;
    };

    const instance = getCurrentInstance();
    const sanitize = instance?.appContext.config.globalProperties.$sanitize;

    onMounted(() => {
      const widgetConfig = window.widgetConfig;
      if (typeof widgetConfig !== "undefined") {
        sfConfig.value = widgetConfig;
      }
    });

    return {
      sfConfig,
      buyDate,
      sellDate,
      flatArea,
      changedFlatArea,
      roomsCount,
      changedRoomsCount,
      purchasePrice,
      changedPurchasePrice,
      purchasePriceGarage,
      changedPurchasePriceGarage,
      purchasePriceBasement,
      changedPurchasePriceBasement,
      renovationPrice,
      changedRenovationPrice,
      interiorFinishingPrice,
      changedInteriorFinishingPrice,
      //--------------------------
      sellPrice,
      changedSellPrice,
      sellPriceGarage,
      changedSellPriceGarage,
      sellPriceBasement,
      changedSellPriceBasement,
      //-------wyliczenia-------------------
      totalPurchasePrice,
      totalCosts,
      areaPurchasePrice,
      areaRenovationPrice,
      areaInteriorFinishingPrice,
      totalSellPrice,
      areaSellPrice,
      investProfit,
      areaProfit,
      flatProfitAfterRenovation,
      garageProfit,
      basementProfit,
      incomeTaxValue,
      healthTaxValue,
      afterTaxProfit,
      profitRate,
      profitPeriod,
      printPdf,
      onProgress,
      //--------
      //------selekty
      incomeTax,
      incomeTaxItems,
      healthTax,
      healthTaxItems,
      //------
      showAsCurrency,
      showAsPercent2,

      showResults,
      isLoading,

      closeContact,
      modal,

      inputForceUpdate,
    };
  },
});
