
import { defineComponent, onMounted, ref, toRaw, watch } from "vue";

export default defineComponent({
  name: "InputText",
  props: {
    inputValue: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    description: {
      type: String,
      default: "",
    },
  },
  emits: ["changedVal"],
  setup: function (props, ctx) {
    const localInputValue = ref(0);
    const tmpVal = ref(props.inputValue);

    watch(localInputValue, (newValue) => {
      if (typeof newValue === "string") {
        localInputValue.value = parseInt(newValue);
      }
      ctx.emit("changedVal", localInputValue.value);
    });

    const incrementVal = () => {
      if (
        props.maxValue !== 0 &&
        localInputValue.value + props.step > props.maxValue
      ) {
        return;
      }
      localInputValue.value += props.step;
      ctx.emit("changedVal", localInputValue.value);
    };

    const decrementVal = () => {
      if (localInputValue.value - props.step >= props.minValue) {
        localInputValue.value -= props.step;
        ctx.emit("changedVal", localInputValue.value);
      }
    };

    onMounted(() => {
      localInputValue.value = toRaw(tmpVal.value);
    });

    return {
      localInputValue,
      incrementVal,
      decrementVal,
    };
  },
});
